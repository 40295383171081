<template>
  <div
    class="chat-profile-sidebar"
    :class="{'show': shallShowUserProfileSidebar}"
  >
    <!-- Header -->
    <header class="chat-profile-header">
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('close-sidebar')"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="70"
            :src="userData.avatar"
          />
        </div>
        <h4 class="chat-user-name">
          {{ userData.name }}
        </h4>
        <span class="user-post">{{ userData.email }}</span>
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="profile-sidebar-area scroll-area"
    >

      <h6 class="section-label mb-1 mt-2">
        {{ t('chat.settings') }}
      </h6>
      <li class="d-flex justify-content-between align-items-center mb-1">
        <div class="d-flex align-items-center">
          <feather-icon
            icon="BookmarkIcon"
            size="18"
            class="mr-75"
          />
          <span class="align-middle">{{ t('chat.autoTransSelectedText') }}</span>
        </div>
        <b-form-checkbox
          v-model="usrSettings.showTrans"
          switch
          @change="changeUsrSettings"
        />
      </li>

      <!-- Status -->
      <h6 class="section-label mb-1 mt-2">
        {{ t('chat.status') }}
      </h6>
      <div class="mb-4">
        <span
          v-if="userData.is_premium"
          class="text-success">{{ userData.subscription_name }}</span>
        <span
          v-else
          class="text-danger">{{ t('limited') }}</span>
      </div>
      <div class="mb-2">
        <b-button
          v-if="!userData.is_premium"
          variant="success"
          @click="goToProfile(0)"
        >
          {{ t('chat.upgrToGetMore') }}
        </b-button>
      </div>

      <div class="mt-3">
        <div class="mb-1"><span>{{ t('chat.youSeeOnlyFilteredAss') }}</span></div>
        <div class="mb-2"><span>{{ t('chat.changeItHere') }}</span></div>
        <b-button
          variant="outline-primary"
          @click="goToProfile(1)"
        >
          {{ t('chat.targetLearnLanguages') }}
        </b-button>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BFormCheckbox, // BFormTextarea, BFormRadioGroup, BFormRadio, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import useJwt from '@/auth/jwt/useJwt'
import { ref } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BAvatar,
    // BFormTextarea,
    // BFormRadioGroup,
    // BFormRadio,
    // BFormCheckbox,
    BButton,
    VuePerfectScrollbar,
    BFormCheckbox,
  },
  props: {
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    goToProfile(tabNum) {
      if (this.$route.name !== 'pages-profile') {
        this.$router.push({ name: 'pages-profile', params: { tab: tabNum } })
      }
    },
  },
  setup() {
    const { t } = useI18nUtils()
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const usrSettings = ref(
      {
        showTrans: true,
      },
    )
    const lsUsrSettings = JSON.parse(localStorage.getItem('usrSettings'))
    if (lsUsrSettings) {
      usrSettings.value = lsUsrSettings
    }

    const changeUsrSettings = () => {
      localStorage.setItem('usrSettings', JSON.stringify(usrSettings.value))
    }

    const userStatusOptions = [
      { text: 'Active', value: 'online', radioVariant: 'success' },
      { text: 'Do Not Disturb', value: 'busy', radioVariant: 'danger' },
      { text: 'Away', value: 'away', radioVariant: 'warning' },
      { text: 'Offline', value: 'offline', radioVariant: 'secondary' },
    ]

    return {
      perfectScrollbarSettings,
      userStatusOptions,
      changeUsrSettings,
      usrSettings,
      // showTransData,
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
#user-status-options ::v-deep {
  .custom-radio {
    margin-bottom: 1rem;
  }
}
</style>
