<template>
  <div class="mr-1 micbuttons">

    <feather-icon
      v-if="isOnRecording"
      icon="StopCircleIcon"
      size="28"
      class="on-record"
      @click="stop"
    />
    <feather-icon
      v-else
      icon="MicIcon"
      size="28"
      class="ready"
      @click="recordAudio"
    />

  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from '@vue/composition-api'

export default {
  setup(props, context) {
    const MAX_AUDIO_TIME = 90
    let intervalID = null
    let currRecordSeconds = 0
    const recorder = ref(null)
    const chunks = ref([])
    const isOnRecording = ref(false)

    // const device = navigator.mediaDevices.getUserMedia({ audio: true })

    const blobObj = ref(null)

    const stop = () => {
      clearInterval(intervalID)
      intervalID = null
      isOnRecording.value = false
      context.emit('is-on-recording', isOnRecording.value)
      recorder.value.stop()
    }

    const audioTimer = () => {
      currRecordSeconds += 1
      if (currRecordSeconds >= MAX_AUDIO_TIME) {
        stop()
      }
      context.emit('change-progress', { ccurSec: currRecordSeconds, maxSec: MAX_AUDIO_TIME })
    }

    const recordAudio = () => {
      try {
        const device = navigator.mediaDevices.getUserMedia({ audio: true })
        device.then(stream => {
          currRecordSeconds = 0
          context.emit('change-progress', { ccurSec: 0, maxSec: MAX_AUDIO_TIME })
          intervalID = window.setInterval(audioTimer, 1000)
          isOnRecording.value = true
          context.emit('is-on-recording', isOnRecording.value)
          recorder.value = new MediaRecorder(stream)
          recorder.value.ondataavailable = e => {
            chunks.value.push(e.data)
            if (recorder.value.state === 'inactive') {
              const blob = new Blob(chunks.value, { type: 'audio/webm' })
              const file = new File([blob], 'rec.webm')
              blobObj.value = blob
              chunks.value = []
              // context.emit('send-audio', blobObj.value)
              context.emit('send-audio', file)
              // console.log(blobObj.value)
              blobObj.value = null

              // save file to browser
              // var a = document.createElement("a"),
              // url = URL.createObjectURL(file);
              // a.href = url;
              // a.download = 'filename.webm';
              // document.body.appendChild(a);
              // a.click();
              // setTimeout(function() {
              //     document.body.removeChild(a);
              //     window.URL.revokeObjectURL(url);
              // }, 0);
            }
          }
          recorder.value.start()
        })
          .catch(e => {
            isOnRecording.value = false
            context.emit('is-on-recording', isOnRecording.value)
            context.emit('show-audio-error', `Error accessing microphone: ${e.message}`)
            // console.error('Error accessing microphone: ', e.message)
          })
      } catch (e) {
        isOnRecording.value = false
        context.emit('is-on-recording', isOnRecording.value)
        context.emit('show-audio-error', `Error accessing microphone: ${e.message}`)
      } finally {
        clearInterval(intervalID)
      }
    }

    onMounted(() => {
      // device.catch(error => {
      //   console.error('Error accessing microphone:', error)
      // })
    })

    onBeforeUnmount(() => {
      clearInterval(intervalID)
      intervalID = null
    })

    return {
      recorder,
      chunks,
      // device,
      blobObj,
      recordAudio,
      stop,
      isOnRecording,
    }
  },
}
</script>

<style lang="scss" scoped>
.micbuttons {
  cursor: pointer;
  .on-record {
    color: #ea5455;
  }
  .ready {
    color: #7367f0;
  }

}

</style>
