<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
        @click="startConversation"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
        >
          {{ t('chat.startConversation') }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                @click.native="shallShowActiveChatContactSidebar=true"
              />
              <h6 class="mb-0 topassisname">
                <img
                  :src="activeChat.contact.languageIcon"
                  height="18"
                  class="ass-lang-icon"
                >{{ activeChat.contact.fullName }}
              </h6>

              <div
                v-if="activeChat.contact.istyping"
                class="mb-0 pl-1"
              >
                <div class="typingdots">
                  <span />
                  <span />
                  <span />
                </div>
              </div>

            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                :disabled="isDeletingChat || activeChat.contact.istyping || (activeChat.chat ? activeChat.chat.chat == undefined: true)"
                class="mr-1 d-md-block d-none pl-1 pr-1 font-weight-normal"
                @click.prevent="deleteMessagesOfAssistant"
              >
                <feather-icon
                  icon="FileIcon"
                  size="14"
                />
                {{ t('chat.newChat') }}
              </b-button>

              <a
                href="https://www.topolingua.com/help-center"
                target="_blank"
              >
                <feather-icon
                  icon="HelpCircleIcon"
                  size="20"
                  class="cursor-pointer d-sm-block d-none mr-50"
                /></a>
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    :disabled="isDeletingChat || activeChat.contact.istyping || (activeChat.chat ? activeChat.chat.chat == undefined: true)"
                    @click.prevent="deleteMessagesOfAssistant"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="14"
                      class="dropicons align-middle"
                    />{{ t('chat.newChat') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="(activeChat.chat ? activeChat.chat.chat == undefined: true)"
                    @click.prevent="exportLogAsTxt"
                  >
                    <feather-icon
                      icon="ExternalLinkIcon"
                      size="14"
                      class="dropicons align-middle"
                    />{{ t('chat.exportAsText') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </vue-perfect-scrollbar>

        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-textarea
              ref="refInputMesage"
              v-model="chatInputMessage"
              :placeholder="isOnRecording ? t('chat.speakToRecordMsg', { minutes: 1.5 }) : t('chat.enterYourMessageMsg')"
              rows="2"
              max-rows="2"
              no-resize
              :disabled="isOnRecording"
              :maxlength="messageMaxLen"
              @keypress.ctrl.10.13.prevent="sendMessage"
            />
          </b-input-group>
          <voice-record
            v-if="activeChat.contact.acceptAudio"
            @send-audio="sendAudio"
            @show-audio-error="showAudioError"
            @is-on-recording="setIsOnRecording"
            @change-progress="changeProgress"
          />
          <div>
            <b-button
              variant="primary"
              type="submit"
              :disabled="isOnRecording || activeChat.contact.istyping"
              class="pl-1 pr-1"
            >
              <feather-icon
                icon="SendIcon"
                size="24"
              />
              <span class="d-none d-md-inline-block">{{ t('chat.send') }}</span>
            </b-button>
            <div class="progrblock">
              <b-progress
                v-if="isOnRecording"
                :value="progressValue"
                max="100"
                striped
                animated
                height="5px"
              />
            </div>
          </div>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
    <b-modal
      id="modal-upgrade-accaunt"
      ref="modalUpgradeAccaunt"
      centered
      :title="t('chat.messageLimitExceeded')"
      modal-class="modal-danger"
      hide-footer
    >
      <div class="d-block text-center">
        <p>{{ t('chat.messageLimitExceededText') }}</p>
      </div>
      <b-button
        variant="outline-success"
        class="mt-2"
        block
        @click="goToPrice()"
      ><feather-icon icon="AwardIcon" class="mr-50" />{{ t('chat.upgradeAccount') }}</b-button>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, nextTick, onMounted
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup,
  // BFormInput,
  BButton, BFormTextarea, BProgress, BModal,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
// eslint-disable-next-line
import useJwt from '@/auth/jwt/useJwt'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line
import { useRouter } from '@core/utils/utils'

import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

import ChatLeftSidebar from './ChatLeftSidebar.vue'
import VoiceRecord from './VoiceRecord.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
import socket from './socket'
import { clearUserDataPartial } from '@/auth/utils'

export default {
  components: {

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    // BFormInput,
    BFormTextarea,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    VoiceRecord,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
    BProgress,
    BModal,
  },
  directives: {
    Ripple,
  },
  setup(props, context) {
    // const route = context.root.$route
    // if (route.params.needToRefreshPage) {
    //   window.location.reload()
    // }

    const { t } = useI18nUtils()
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    const toast = useToast()
    const { router } = useRouter()

    const isOnRecording = ref(false)
    const chatInputAudio = ref(null)

    const progressValue = ref(0)

    const setIsOnRecording = value => {
      isOnRecording.value = value
    }

    const totalCharacters = ref(0)
    const messageMaxLen = ref(1000)

    const toastSuccessTitle = t('toast.success')
    const modalUpgradeAccaunt = ref()

    const goToPrice = () => {
      router.push({ name: 'pages-pricing' })
    }

    // let lastDateOfSendMessage = new Date()

    // if (route.params.needrepaint) {
    //   alert(777777)
    // }

    const showAudioError = err => {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: t('toast.errMicrophone'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: err,
        },
      })
    }

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    const { resolveAvatarBadgeVariant } = useChat()
    

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const refInputMesage = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
      if (store.state.app.windowWidth > $themeBreakpoints.sm) {
        refInputMesage.value.focus()
      }
      // refInputMesage.value.focus()
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])

    const logout = () => {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // localStorage.removeItem('userData')
      clearUserDataPartial()
      router.push({ name: 'auth-login', params: { needToRefreshPage: true } })
    }

    // const fetchChatAndContacts = () => {
    //   store.dispatch('app-chat/fetchChatsAndContacts')
    //     .then(response => {
    //       chatsContacts.value = response.data.chatsContacts
    //       console.log('* chatsContacts.value:')
    //       console.log(JSON.stringify(chatsContacts.value))
    //       contacts.value = response.data.contacts
    //       console.log('* contacts.value:')
    //       console.log(JSON.stringify(contacts.value))

    //       // eslint-disable-next-line no-use-before-define
    //       profileUserDataMinimal.value = response.data.profileUser
    //       // eslint-disable-next-line no-use-before-define
    //       // console.log('profileUserDataMinimal.value:')
    //       // console.log(JSON.stringify(profileUserDataMinimal.value))
    //     })
    // }
    const toastErrGetAssistantsTile = t('toast.errGetAssistants')
    const getAssistants = () => {
      useJwt.getAssistants({
        // src_text: this.textQuiz,
      })
        .then(response => {
          const resData = response.data
          // chatsContacts.value = resData
          chatsContacts.value = resData.filter(e => e.chat)
          // console.log('chatsContacts.value --')
          // console.log(JSON.stringify(chatsContacts.value))
          contacts.value = resData
          // console.log('contacts.value --')
          // console.log(JSON.stringify(contacts.value))

          // contacts.value = [
          //   {
          //     id: 1,
          //     fullName: 'Felecia Rower',
          //     role: 'Frontend Developer',
          //     about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
          //     avatar: require('@/assets/images/avatars/1.png'),
          //     status: 'offline',
          //   },
          // ]

          // eslint-disable-next-line no-use-before-define
          // profileUserDataMinimal.value = {
          //   id: 1, // 11
          //   avatar: '/img/8.4ac55be3.png',
          //   fullName: 'John Doe',
          //   status: 'online',
          // }
          const userData = JSON.parse(localStorage.getItem('userData'))
          // eslint-disable-next-line no-use-before-define
          profileUserDataMinimal.value = {
            id: userData.id, // 11
            avatar: userData.avatar ? userData.avatar : '',
            fullName: userData.name,
            status: 'online',
          }
          // console.log(JSON.stringify(resData))
        })
        .catch(error => {
          if (error.status === 401) {
            logout()
          } else {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: toastErrGetAssistantsTile,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email,
              },
            })
            // console.log('error.data.email')
            // console.log(error.data.email)
          }
        })
        .finally(() => {
          // this.seenTable = true
        })
    }

    const toasterrGetUserLangSettingsTitle = t('toast.errGetUserLangSettings')
    const checkIfUserLanguagesConfigured = () => {
      useJwt.isUserLanguagesConfigured({
      })
        .then(response => {
          const resData = response.data
          if (!resData.isUserLanguagesConfigured) {
            router.push({ name: 'pages-profile', params: { tab: 1, msgAboutEmptyLanguages: true, returnTo: 'apps-chat' } })
          }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: toasterrGetUserLangSettingsTitle,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data.email,
            },
          })
        })
        .finally(() => {
        })
    }

    checkIfUserLanguagesConfigured()
    getAssistants()
    // fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')

    // const openChatOfContact = userId => {
    //   // Reset send message input value
    //   chatInputMessage.value = ''

    //   store.dispatch('app-chat/getChat', { userId })
    //     .then(response => {
    //       activeChat.value = response.data
    //       console.log('activeChat.value 1')
    //       console.log(JSON.stringify(activeChat.value))
    //       // Set unseenMsgs to 0
    //       const contact = chatsContacts.value.find(c => c.id === userId)
    //       if (contact) contact.chat.unseenMsgs = 0
    //       activeChat.value.contact = contact

    //       // Scroll to bottom
    //       nextTick(() => { scrollToBottomInChatLog() })
    //     })

    //   // if SM device =>  Close Chat & Contacts left sidebar
    //   // eslint-disable-next-line no-use-before-define
    //   mqShallShowLeftSidebar.value = false
    // }

    const toastErrGetChatsTitle = t('toast.errGetChats')
    const openChatOfContact = (userId, getOnlyForMessageId) => {
      // Reset send message input value
      chatInputMessage.value = ''

      useJwt.getChats(
        userId, // it's assistant id
        getOnlyForMessageId,
      )
        .then(response => {
          const aditionalChatData = response.data
          let currentChatToRefresh = true

          if (getOnlyForMessageId === 0) {
            activeChat.value = response.data
          } else {
            currentChatToRefresh = userId === activeChat.value.chat.id
            if (currentChatToRefresh) {
              activeChat.value.chat.unseenMsgs = aditionalChatData.chat.unseenMsgs
              activeChat.value.chat.chat = activeChat.value.chat.chat.concat(aditionalChatData.chat.chat)
            }
          }

          // console.log('activeChat.value****')
          // console.log(JSON.stringify(activeChat.value))
          // Set unseenMsgs to 0
          const contact = chatsContacts.value.find(c => c.id === userId)
          if (getOnlyForMessageId !== 0) {
            contact.istyping = false
            contact.status = 'online'
          }
          // const contact = chatsContacts.value.find(c => c.id === 2)
          // console.log('contact****')
          // console.log(JSON.stringify(contact))
          // console.log('chatsContacts****')
          // console.log(JSON.stringify(chatsContacts))
          // const contact = chatsContacts.value
          if (getOnlyForMessageId === 0) {
            if (contact) contact.chat.unseenMsgs = 0
          } else if (contact) {
            contact.chat.unseenMsgs += aditionalChatData.chat.unseenMsgs
            // console.log('aditionalChatData.chat.unseenMsgs')
            // console.log(aditionalChatData.chat.unseenMsgs)
          }
          // new !!!!
          if (currentChatToRefresh) {
            if (contact) activeChat.value.contact = contact
          }

          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })
        .catch(error => {
          if (error.status === 401) {
            logout()
          } else {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: toastErrGetChatsTitle,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email,
              },
            })
            // console.log('error.data.email')
            // console.log(error.data.email)
          }
        })
        .finally(() => {
          // this.seenTable = true
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const isDeletingChat = ref(false)
    const toasterrDelMessagesTitle = t('toast.errDelMessages')
    const deleteChats = assId => {
      isDeletingChat.value = true
      useJwt.deleteСhats(assId)
        .then(response => {
          const resData = response.data
          getAssistants()
          openChatOfContact(assId, 0)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: toastSuccessTitle,
              icon: 'MessageSquareIcon',
              variant: 'success',
              text: resData.detail,
            },
          })
        })
        .catch(error => {
          if (error.status === 401) {
            logout()
          } else {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: toasterrDelMessagesTitle,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.email,
              },
            })
          }
        })
        .finally(() => {
          isDeletingChat.value = false
        })
    }

    const msgBoxTitle = t('confirm.pleaseConfirm')
    const msgBoxMsg1 = t('confirm.delConversation.msg1')
    const msgBoxMsg2 = t('confirm.delConversation.msg2')
    const msgBoxOk = t('confirm.delConversation.ok')
    const msgBoxCancel = t('confirm.cancel')
    const msgBox2Title = t('confirm.areYouShure')
    const msgBox2Msg1 = t('confirm.delConversation2.msg1')
    const msgBox2Msg2 = t('confirm.delConversation2.msg2')
    const msgBox2Ok = t('confirm.delConversation2.ok')

    const deleteMessagesOfAssistant = () => {
      context.root.$bvModal
        .msgBoxConfirm(`${msgBoxMsg1}${activeChat.value.contact.fullName}${msgBoxMsg2}`, {
          title: msgBoxTitle,
          size: 'sm',
          okVariant: 'danger',
          okTitle: msgBoxOk,
          cancelTitle: msgBoxCancel,
          cancelVariant: 'primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            context.root.$bvModal
              .msgBoxConfirm(`${msgBox2Msg1}${activeChat.value.contact.fullName}${msgBox2Msg2}`, {
                title: msgBox2Title,
                size: 'sm',
                okVariant: 'danger',
                okTitle: msgBox2Ok,
                cancelTitle: msgBoxCancel,
                cancelVariant: 'primary',
                hideHeaderClose: false,
                centered: true,
              })
              .then(value2 => {
                if (value2 === true) {
                  deleteChats(activeChat.value.contact.id)
                }
              })
          }
        })
    }

    const lineBreakToHTML = txt => txt.replaceAll('\n', '<br>')
    const brToLineBreak = txt => txt.replaceAll('<br>', '\n')

    const exportLogAsTxt = () => {
      let csvContent = ''

      const msgArr = activeChat.value.chat.chat
      for (let i = 0; i < msgArr.length; i += 1) {
        let lineInfo = msgArr[i].time
        lineInfo = msgArr[i].sentFromUser ? `U: ${lineInfo} ` : `A: ${lineInfo} `
        const msg = brToLineBreak(msgArr[i].message)
        csvContent += `${lineInfo}\r\n${msg}\r\n\r\n`
      }

      const currentdate = new Date()
      const filename = `${activeChat.value.chat.contact.fullName} ${currentdate.getFullYear()}-${currentdate.getMonth() + 1}-${currentdate.getDate()}.txt`
      // const filename = `${activeChat.value.chat.contact.fullName}.txt`
      // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }

    const handleWsMessage = msg => {
      // console.log('**********************------------****************************')
      // console.log(msg)
      // {"message_id": 75, "assistant_id": 2, "type": "msg_proc"}
      const msgData = JSON.parse(msg)
      if (msgData.type === 'msg_proc') {
        openChatOfContact(msgData.assistant_id, msgData.message_id)
        // console.log('-----------------------------------------------')
      }
    }
    const handleWsError = msg => {
      console.log('**********************------ e r r o r ------****************************')
      console.log(msg)
    }

    socket.$on('message', handleWsMessage)
    socket.$on('error', handleWsError)

    // new 13-03-2025
    // onMounted(() => {
    //   console.log('mountedddddddddddddddddddddddddddddddddddddddddddddd')
    //   socket.$on('message', handleWsMessage)
    //   socket.$on('error', handleWsError)
    // })

    // UnRegister on leave
    onUnmounted(() => {
      // console.log('unmounteeeeeeeeeeeeeeeeeeeeeeed')
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
      socket.$off('message', handleWsMessage)
      socket.$off('error', handleWsError)
    })

    // const sendMessage = () => {
    //   if (!chatInputMessage.value) return
    //   const payload = {
    //     contactId: activeChat.value.contact.id,
    //     // eslint-disable-next-line no-use-before-define
    //     senderId: profileUserDataMinimal.value.id,
    //     message: chatInputMessage.value,
    //   }
    //   store.dispatch('app-chat/sendMessage', payload)
    //     .then(response => {
    //       const { newMessageData, chat } = response.data
    //       console.log('****************************** response.data ***********')
    //       console.log(JSON.stringify(response.data))

    //       // ? If it's not undefined => New chat is created (Contact is not in list of chats)
    //       if (chat !== undefined) {
    //         activeChat.value = { chat, contact: activeChat.value.contact }
    //         chatsContacts.value.push({
    //           ...activeChat.value.contact,
    //           chat: {
    //             id: chat.id,
    //             lastMessage: newMessageData,
    //             unseenMsgs: 0,
    //           },
    //         })
    //       } else {
    //         // Add message to log
    //         activeChat.value.chat.chat.push(newMessageData)
    //       }

    //       // Reset send message input value
    //       chatInputMessage.value = ''

    //       // Set Last Message for active contact
    //       const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
    //       contact.chat.lastMessage = newMessageData

    //       // Scroll to bottom
    //       nextTick(() => { scrollToBottomInChatLog() })
    //     })
    // }
    const toastErrSendMsgTitle = t('toast.errSendMessage')

    const sendMessage = () => {
      if (!chatInputMessage.value) return
      if (activeChat.value.contact.istyping) return
      activeChat.value.contact.istyping = true

      // if (activeChat.value.contact) {
      //   if (activeChat.value.contact.activeChatIsSendingMessage || isOnRecording.value || activeChat.value.contact.istyping) {
      //     return
      //   }
      // }

      // const ccurDate = new Date()
      // console.log('ccurDate - lastDateOfSendMessage')
      // console.log(ccurDate - lastDateOfSendMessage)
      // if ((ccurDate - lastDateOfSendMessage) < 1000) {
      //   lastDateOfSendMessage = ccurDate
      //   return
      // }
      // lastDateOfSendMessage = ccurDate

      // activeChat.value.contact.activeChatIsSendingMessage = true
      

      let textToSend = chatInputMessage.value.trim()
      const lentext = textToSend.length
      if (lentext < 1) {
        return
      }
      if (lentext > messageMaxLen.value) {
        textToSend = textToSend.slice(0, messageMaxLen.value)
      }
      useJwt.sendMessage(
        activeChat.value.contact.id,
        {
          text: textToSend,
        },
      )
        .then(response => {
          // {"newMessageData":{"message":"sdf","time":"2023-08-13T12:13:30.029Z","senderId":1,"sentFromUser":true}}
          const { newMessageData, chat } = response.data
          const newMessageDataInHTML = newMessageData
          newMessageDataInHTML.message = lineBreakToHTML(newMessageData.message)

          // ? If it's not undefined => New chat is created (Contact is not in list of chats)
          // if (chat !== undefined) {
          // console.log('JSON.stringify(activeChat.value)')
          // console.log(JSON.stringify(activeChat.value))
          if (activeChat.value.chat === undefined) {
            // activeChat.value = { chat, contact: activeChat.value.contact }
            chatsContacts.value.push({
              ...activeChat.value.contact,
              chat: {
                id: chat.id,
                lastMessage: newMessageDataInHTML,
                unseenMsgs: 0,
              },
            })
            openChatOfContact(chat.id, 0)
          } else {
            // Add message to log
            activeChat.value.chat.chat.push(newMessageDataInHTML)
          }

          // Reset send message input value
          chatInputMessage.value = ''

          // Set Last Message for active contact
          const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
          contact.chat.lastMessage = newMessageData
          contact.chat.unseenMsgs = 0
          // activeChat.contact.istyping = true
          contact.istyping = true
          contact.status = 'busy'

          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        })
        .catch(error => {
          if (error.status === 401) {
            logout()
          } else if (error.status === 402) {
            activeChat.value.contact.istyping = false
            modalUpgradeAccaunt.value.show()
          } else {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: toastErrSendMsgTitle,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.detail ? error.data.detail : '',
              },
            })
          }
        })
        .finally(() => {
          chatInputAudio.value = null
          // this.seenTable = true
          // activeChat.value.contact.activeChatIsSendingMessage = false
          // activeChat.value.contact.istyping = false
        })
    }

    const sendAudio = recordedAudioFile => {
      if (!recordedAudioFile) return

      if (activeChat.value.contact.istyping) return
      activeChat.value.contact.istyping = true

      const fdata = new FormData()
      // fdata.append('files.file', recordedAudioFile)
      fdata.append('audiofile', recordedAudioFile)

      useJwt.sendAudio(
        activeChat.value.contact.id,
        fdata,
      )
        .then(response => {
          const { newMessageData, chat } = response.data
          const newMessageDataInHTML = newMessageData
          newMessageDataInHTML.message = lineBreakToHTML(newMessageData.message)
          if (activeChat.value.chat === undefined) {
            chatsContacts.value.push({
              ...activeChat.value.contact,
              chat: {
                id: chat.id,
                lastMessage: newMessageDataInHTML,
                unseenMsgs: 0,
              },
            })
            openChatOfContact(chat.id, 0)
          } else {
            activeChat.value.chat.chat.push(newMessageDataInHTML)
          }

          chatInputMessage.value = ''

          const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
          contact.chat.lastMessage = newMessageData
          contact.chat.unseenMsgs = 0

          contact.istyping = true
          contact.status = 'busy'

          nextTick(() => { scrollToBottomInChatLog() })
        })
        .catch(error => {
          if (error.status === 401) {
            logout()
          } else {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: toastErrSendMsgTitle,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.detail,
              },
            })
            // console.log('error.data.detail')
            // console.log(error.data.detail)
          }
        })
        .finally(() => {
          chatInputAudio.value = null
          // this.seenTable = true
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser')
        .then(response => {
          profileUserData.value = response.data
          shallShowUserProfileSidebar.value = true
        })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const toastTitle = t('toast.selectAssTitle')
    const toastText = t('toast.selectAssText')
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      } else {
        // if (chatsContacts.value && (chatsContacts.value.length > 0)) {
        //   openChatOfContact(chatsContacts.value[0].id, 0)
        // } else ...
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: toastTitle,
            icon: 'InfoIcon',
            variant: 'info',
            text: toastText,
            solid: true,
          },
        })
      }
    }

    const changeProgress = ({ ccurSec, maxSec }) => {
      progressValue.value = (ccurSec / maxSec) * 100
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      chatInputAudio,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      refInputMesage,
      sendAudio,
      showAudioError,
      setIsOnRecording,
      isOnRecording,
      deleteMessagesOfAssistant,
      isDeletingChat,
      checkIfUserLanguagesConfigured,
      exportLogAsTxt,
      changeProgress,
      progressValue,
      totalCharacters,
      messageMaxLen,
      t,
      goToPrice,
      modalUpgradeAccaunt,
    }
  },
}
</script>

<style lang="scss" scoped>
  $dot-width: 5px;
  $dot-color: #5e50ee;
  $speed: 1.5s;

  .typingdots {
    position: relative;

    span {
      content: '';
      animation: blink $speed infinite;
      animation-fill-mode: both;
      height: $dot-width;
      width: $dot-width;
      background: $dot-color;
      position: absolute;
      left:0;
      top:0;
      border-radius: 50%;

      &:nth-child(2) {
        animation-delay: .2s;
        margin-left: $dot-width * 1.5;
      }

      &:nth-child(3) {
        animation-delay: .4s;
        margin-left: $dot-width * 3;
      }
    }
  }

  @keyframes blink {
    0% {
      opacity: .1;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .1;
    }
  }

  .badge-danger {
    background-color: #5e50ee;
  }

  .start-chat-area {
    cursor: pointer;
  }

  .dropicons {
    margin-right: 3px;
  }

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>

<style lang="scss" scoped>
  .topassisname {
    font-size: 18px;
    font-weight: bold;
  }
  img.ass-lang-icon {
    padding-bottom: 2px;
    border: none;
    padding-right: 3px;
  }
  .progrblock {
    margin-top: 2px;
  }

</style>
