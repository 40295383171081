<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      :src="user.avatar"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.status)"
      variant="transparent"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0 vertical-align">
        <img
          :src="user.languageIcon"
          height="16"
          class="ass-lang-icon"
        >{{ user.fullName }}
      </h5>
      <p class="card-text text-truncate">
        {{ isChatContact ? filterTags(user.chat.lastMessage.message) : user.about }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(user.chat.lastMessage.time, { hour: 'numeric', minute: 'numeric' }) }}</small>
      <b-badge
        v-if="user.chat.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.chat.unseenMsgs }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort, filterTags } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant, filterTags }
  },
}
</script>

<style lang="scss" scoped>
  .chat-application .sidebar-content .chat-user-list-wrapper li img.ass-lang-icon {
    padding-bottom: 4px;
    border: none;
    padding-right: 3px;
  }

</style>
