import { render, staticRenderFns } from "./VoiceRecord.vue?vue&type=template&id=50af8b44&scoped=true&"
import script from "./VoiceRecord.vue?vue&type=script&lang=js&"
export * from "./VoiceRecord.vue?vue&type=script&lang=js&"
import style0 from "./VoiceRecord.vue?vue&type=style&index=0&id=50af8b44&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50af8b44",
  null
  
)

export default component.exports