<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="chats">

    <div
      v-if="chatData.contact.greeting"
      key="greetdiv"
      class="chat chat-group d-flex align-start mb-4 chat-left"
    >
      <div
        class="chat-avatar me-4"
      >
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="chatData.contact.avatar"
        />
      </div>
      <div
        class="chat-body d-inline-flex flex-column align-start"
      >
        <div
          key="usergreeting"
          class="chat-content elevation-1 bg-surface chat-left mb-2"
        >
          <div
            @touchstart="selectText()"
            @mouseup.prevent="selectText()"
            v-html="chatData.contact.greeting"
          />
        </div>
      </div>
    </div>

    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)+String(msgGrp.messages[0].sendFromUser)"
      class="chat chat-group d-flex align-start"
      :class="[{
        'flex-row-reverse': msgGrp.messages[0].sendFromUser,
        'mb-4': formattedChatData.formattedChatLog.length - 1 !== index,
        'chat-left': !msgGrp.messages[0].sendFromUser
      }]"
    >
      <div
        class="chat-avatar"
        :class="msgGrp.messages[0].sendFromUser ? 'ms-4' : 'me-4'"
      >
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="!msgGrp.messages[0].sendFromUser ? formattedChatData.contact.avatar : profileUserAvatar"
        />
      </div>
      <div
        class="chat-body d-inline-flex flex-column"
        :class="msgGrp.messages[0].sendFromUser ? 'align-end' : 'align-start'"
      >
        <div
          v-for="(msgData, msgIndex) in msgGrp.messages"
          :key="msgData.time"
          class="chat-content elevation-1"
          :class="[
            !msgData.sendFromUser ? 'bg-surface chat-left' : 'bg-primary text-white chat-right',
            msgGrp.messages.length - 1 !== msgIndex ? 'mb-2' : 'mb-1',
          ]"
        >
          <div
            v-if="msgData.msg"
            :class="(msgData.msg && msgData.audio) ? 'pb-1' : ''"
            @touchstart="selectText()"
            @mouseup.prevent="selectText()"
            v-html="msgData.msg"
          />
          <div class="ablock">
            <audio
              v-if="msgData.audio"
              controls
              preload="metadata"
            >
              <source
                :src="msgData.audio"
                type="audio/mp3"
              >
              Unsupported browser!
            </audio>
          </div>

        </div>
        <div :class="{ 'text-right': msgGrp.messages[0].sendFromUser }">
          <span class="text-sm me-1 text-disabled">{{ formatDateToMonthShort(msgGrp.messages[msgGrp.messages.length - 1].time, { hour: 'numeric', minute: 'numeric' }) }}</span>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-add-dictionary"
      ref="modalAddDictionary"
      cancel-variant="outline-secondary"
      :ok-title="$t('modal.save')"
      :cancel-title="$t('modal.cancel')"
      centered
      :title="$t('modal.addPhrase.title')"
      :ok-disabled="!selectedTxt"
      @hide="closeTransForm"
      @ok="addPhrase"
    >
      <b-form>
        <b-form-group>
          <label for="phrase">{{ $t('modal.addPhrase.phrase') }} ({{ transFormSrcLang }})</label>
          <b-form-input
            id="phrase"
            v-model="selectedTxt"
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="trans">{{ $t('modal.addPhrase.translation') }} ({{ transFormDstLang }})</label>
          <b-form-input
            id="trans"
            v-model="translatedTxt"
            type="text"
            :placeholder="transPlaceholder"
          />
        </b-form-group>
        <b-form-group class="text-center">
          <div class="row">
            <div class="col d-flex align-items-center justify-content-end trans-col-left">
              <b-badge
                :variant="transFormIsReverse ? 'light-danger' : 'light-primary'"
                class="d-block"
              >
                {{ transFormSrcLang }}
              </b-badge>
            </div>
            <div class="col-1 text-center pr-0 pl-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="transFormIsReverse ? 'outline-danger' : 'outline-primary'"
                class="trans-btn"
                @click="reverseTrans"
              >
                <feather-icon
                  icon="RepeatIcon"
                  class=""
                />
              </b-button>
            </div>
            <div class="col d-flex align-items-center justify-content-start trans-col-right">
              <b-badge
                :variant="transFormIsReverse ? 'light-danger' : 'light-primary'"
                class="d-block"
              >
                {{ transFormDstLang }}
              </b-badge>
            </div>
          </div>
        </b-form-group>
        <div>
          <div>
            <label v-if="contextTxt">{{ $t('modal.addPhrase.context') }}</label>
          </div>
          <div>
            <i>{{ contextTxt }}</i>
          </div>
        </div>
        <div v-if="showTransHelp">
          <div class="mt-2 text-warning">
            <feather-icon
              icon="HelpCircleIcon"
            />
            <small>{{ $t('modal.addPhrase.helpText') }}</small>
          </div>
        </div>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import {
  BAvatar, BModal, BForm, BFormInput, BFormGroup, BButton, BBadge,
} from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { clearUserDatePartial } from '@/auth/utils'


export default {
  components: {
    BAvatar, BModal, BForm, BFormInput, BFormGroup, BButton, BBadge
  },
  directives: {
    Ripple,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const toast = useToast()
    const { router } = useRouter()
    const formatMsg = (msg, audio) => {
      let res = `<span>${msg}<span>`
      if (audio) {
        res += `<audio controls><source src="${audio}" type="audio/mp3"></audio>`
      }
      return res
    }

    let translatedList = []

    const transFormSrcLang = ref(props.chatData.contact.language)
    const transFormDstLang = ref(userData.native_language_name)
    const transFormIsReverse = ref(false)

    // const currPlayingIndex = ref(null)

    // const playPause = cindex => {
    //   currPlayingIndex.value = cindex
    //   console.log('currPlayingIndex.value')
    //   console.log(currPlayingIndex.value)
    // }

    onMounted(() => {
      document.addEventListener('play', e => {
        const audios = document.getElementsByTagName('audio')
        for (let i = 0, len = audios.length; i < len; i += 1) {
          if (audios[i] !== e.target) {
            audios[i].pause()
          }
        }
      }, true)
    })

    // remove \n \r, remove '.' and ',' at the begin and and of line
    const ClearStr = s => s.replace(/\n|\r|<br>/g, '').trim().replace(/^[.,]*/, '').replace(/[.,]*$/, '')
      .trim()

    const getSelectedContext = (txt, anchorOffset, focusOffset) => {
      // console.log(anchorOffset, focusOffset)
      // const sentences = curMsg.split(/(\.|\!|\?)/)
      if (!txt) {
        return null
      }
      const sentences = txt.match(/[^.!?]*[.!?]+/g)
      let res = ''
      if (!sentences) {
        res = txt
        if (res && (res.length > 512)) {
          return null
        }
        return ClearStr(res)
      }

      let beginSel = anchorOffset
      let endSel = focusOffset
      if (beginSel > endSel) {
        [beginSel, endSel] = [endSel, beginSel]
      }
      let firstIndex = 0
      const len = sentences.length
      let lastIndex = len - 1
      let currLen = 0
      let isCalcFirst = false
      let isCalcEnd = false
      for (let i = 0; i < len; i += 1) {
        currLen += sentences[i].length
        if (!isCalcFirst && (beginSel <= currLen)) {
          firstIndex = i
          isCalcFirst = true
        }
        if (!isCalcEnd && (endSel <= currLen)) {
          lastIndex = i
          isCalcEnd = true
        }
      }
      for (let i = firstIndex; i <= lastIndex; i += 1) {
        res = res.concat(sentences[i], '')
      }
      if (res) {
        res = res.trim()
      }
      // is too longth
      if (res.length > 512) {
        return null
      }
      if (res === '') {
        return null
      }
      return ClearStr(res)
    }

    const modalAddDictionary = ref()
    const selectedTxt = ref(null)
    const translatedTxt = ref(null)
    const contextTxt = ref(null)
    const transPlaceholder = ref(null)
    const PLEASE_WAIT = 'Please wait...'

    const logout = () => {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      // localStorage.removeItem('userData')
      clearUserDatePartial()
      router.push({ name: 'auth-login', params: { needToRefreshPage: true } })
    }

    const sendTextToTranslate = textToTrans => {
      // is already translated
      let isAlreadyTranslated = false
      for (let i = 0, len = translatedList.length; i < len; i += 1) {
        const el = translatedList[i]
        if ((transFormIsReverse.value === el.reverse) && (textToTrans === el.text)) {
          translatedTxt.value = el.translate
          isAlreadyTranslated = true
          break
        }
      }

      if (isAlreadyTranslated) {
        return
      }

      useJwt.sendTextToTranslate({
        text: textToTrans,
        source_lang_id: props.chatData.contact.languageId,
        reverse: transFormIsReverse.value,
      })
        .then(response => {
          const resData = response.data
          if (resData.success) {
            if (!translatedTxt.value) {
              translatedTxt.value = resData.translated_text
              let isAddedToTransList = false
              for (let i = 0, len = translatedList.length; i < len; i += 1) {
                const el = translatedList[i]
                if ((transFormIsReverse.value === el.reverse) && (textToTrans === el.text)) {
                  isAddedToTransList = true
                  break
                }
              }
              if (!isAddedToTransList) {
                translatedList.push({
                  reverse: transFormIsReverse.value,
                  text: textToTrans,
                  translate: translatedTxt.value,
                })
              }
            }
          } else {
            transPlaceholder.value = 'Can\'t translate. Please translate manualy'
          }
        })
        .catch(error => {
          transPlaceholder.value = 'Can\'t translate. Please translate manualy'
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error text translation',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data.email,
            },
          })
        })
        .finally(() => {
        })
    }

    const closeTransForm = () => {
      selectedTxt.value = null
      translatedTxt.value = null
      transPlaceholder.value = PLEASE_WAIT
    }

    const usrSettings = ref(
      {
        showTrans: true,
      },
    )

    const showTransHelp = ref(true)

    const selectText = () => {
      transFormIsReverse.value = false
      transFormSrcLang.value = props.chatData.contact.language
      transFormDstLang.value = userData.native_language_name

      translatedList = []
      const lsUsrSettings = JSON.parse(localStorage.getItem('usrSettings'))
      if (lsUsrSettings) {
        usrSettings.value = lsUsrSettings
        showTransHelp.value = false
      }
      if (!usrSettings.value.showTrans) {
        return
      }
      const objSelectedText = window.getSelection()
      if (objSelectedText) {
        const locSelectedText = ClearStr(objSelectedText.toString())
        if ((locSelectedText.length > 0) && (locSelectedText.length < 150)) {
          selectedTxt.value = ClearStr(locSelectedText)
          const curMsg = objSelectedText.anchorNode.data
          contextTxt.value = getSelectedContext(curMsg, objSelectedText.anchorOffset, objSelectedText.focusOffset)
          if (contextTxt.value && (contextTxt.value.length <= (locSelectedText.length + 1))) {
            contextTxt.value = null
          }
          sendTextToTranslate(selectedTxt.value)
          transPlaceholder.value = PLEASE_WAIT
          modalAddDictionary.value.show()
          // window.getSelection().empty()
        }
      }
    }

    const addPhrase = () => {
      useJwt.addPhrase(
        {
          text: selectedTxt.value,
          translation: translatedTxt.value,
          context: contextTxt.value,
          lang_source: props.chatData.contact.languageId,
          reverse: transFormIsReverse.value,
          // lang_target: userData.native_language, // we know it on the back
        },
      )
        .then(response => {
          const resData = response.data
          if (resData.success) {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'The translation is added to your dictionary',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: resData.detail,
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Can\'t add the translation',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: resData.detail,
              },
            })
          }
        })
        .catch(error => {
          if (error.status === 401) {
            logout()
          } else {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error adding the translation',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: error.data.detail,
              },
            })
          }
        })
        .finally(() => {
        })
    }

    const reverseTrans = () => {
      transFormIsReverse.value = !transFormIsReverse.value
      const temp = transFormSrcLang.value
      transFormSrcLang.value = transFormDstLang.value
      transFormDstLang.value = temp

      // const tempT = selectedTxt.value
      // selectedTxt.value = translatedTxt.value
      // translatedTxt.value = tempT

      if ((selectedTxt.value.length > 0) && (selectedTxt.value.length < 150)) {
        selectedTxt.value = ClearStr(selectedTxt.value)
        translatedTxt.value = null
        sendTextToTranslate(selectedTxt.value)
        transPlaceholder.value = PLEASE_WAIT
      }
    }

    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.avatar,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      // console.log('........................................')
      // console.log(props.chatData.contact.languageId)
      // console.log('........................................')

      // console.log('chatLog/////////////////////////////')
      // [{"message":"Hi","time":"2023-08-08T17:59:05.371647","senderId":1,"sentFromUser":true},...]

      // console.log(JSON.stringify(chatLog))

      const formattedChatLog = []
      const chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      // const chatMessageSentFromUserGrouped = chatLog[0] ? chatLog[0].sentFromUser : undefined  //my
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      let firstMessageSentFromUser = chatLog[0] ? chatLog[0].sentFromUser : undefined // my

      chatLog.forEach((msg, index) => {
        if (firstMessageSentFromUser === msg.sentFromUser) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            sendFromUser: msg.sentFromUser, // my
            audio: msg.audio,
            msgAndAudio: formatMsg(msg.message, msg.audio),
          })
        } else {
          firstMessageSentFromUser = msg.sentFromUser
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
              sendFromUser: msg.sentFromUser, // my
              audio: msg.audio,
              msgAndAudio: formatMsg(msg.message, msg.audio),
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
      formatDateToMonthShort,
      // playPause,
      // currPlayingIndex,
      selectText,
      modalAddDictionary,
      selectedTxt,
      contextTxt,
      translatedTxt,
      closeTransForm,
      transPlaceholder,
      addPhrase,
      userData,
      usrSettings,
      showTransHelp,
      transFormSrcLang,
      transFormDstLang,
      transFormIsReverse,
      reverseTrans,
    }
  },
}
</script>

<style lang="scss" scoped>

  .py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

  .px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.mb-1 {
  margin-bottom:4px!important
}
.mb-2 {
  margin-bottom:8px!important
}
.mb-3 {
  margin-bottom:12px!important
}
.mb-4 {
  margin-bottom:16px!important
}
.mb-5 {
  margin-bottom:20px!important
}

/** **/
.me-0 {
  margin-inline-end:0px!important
}
.me-1 {
  margin-inline-end:4px!important
}
.me-2 {
  margin-inline-end:8px!important
}
.me-3 {
  margin-inline-end:12px!important
}
.me-4 {
  margin-inline-end:16px!important
}
.me-5 {
  margin-inline-end:20px!important
}
.me-6 {
  margin-inline-end:24px!important
}
.me-7 {
  margin-inline-end:28px!important
}
.me-8 {
  margin-inline-end:32px!important
}
.me-9 {
  margin-inline-end:36px!important
}
.me-10 {
  margin-inline-end:40px!important
}
.me-11 {
  margin-inline-end:44px!important
}
.me-12 {
  margin-inline-end:48px!important
}
.me-13 {
  margin-inline-end:52px!important
}
.me-14 {
  margin-inline-end:56px!important
}
.me-15 {
  margin-inline-end:60px!important
}
.me-16 {
  margin-inline-end:64px!important
}
.me-auto {
  margin-inline-end:auto!important
}

.ms-0 {
  margin-inline-start:0px!important
}
.ms-1 {
  margin-inline-start:4px!important
}
.ms-2 {
  margin-inline-start:8px!important
}
.ms-3 {
  margin-inline-start:12px!important
}
.ms-4 {
  margin-inline-start:16px!important
}
.ms-5 {
  margin-inline-start:20px!important
}
.ms-6 {
  margin-inline-start:24px!important
}
.ms-7 {
  margin-inline-start:28px!important
}
.ms-8 {
  margin-inline-start:32px!important
}
.ms-9 {
  margin-inline-start:36px!important
}
.ms-10 {
  margin-inline-start:40px!important
}
.ms-11 {
  margin-inline-start:44px!important
}
.ms-12 {
  margin-inline-start:48px!important
}
.ms-13 {
  margin-inline-start:52px!important
}
.ms-14 {
  margin-inline-start:56px!important
}
.ms-15 {
  margin-inline-start:60px!important
}
.ms-16 {
  margin-inline-start:64px!important
}
.ms-auto {
  margin-inline-start:auto!important
}

.text-sm {
  font-size: .8125rem;
  line-height: 1.25rem;
}

.align-start {
  align-items:flex-start!important
}
.align-end {
  align-items:flex-end!important;
}
.align-center {
  align-items:center!important
}
.align-baseline {
  align-items:baseline!important
}
.align-stretch {
  align-items:stretch!important
}
.align-content-start {
  align-content:flex-start!important
}
.align-content-end {
  align-content:flex-end!important
}
.align-content-center {
  align-content:center!important
}
.align-content-space-between {
  align-content:space-between!important
}
.align-content-space-around {
  align-content:space-around!important
}
.align-content-space-evenly {
  align-content:space-evenly!important
}
.align-content-stretch {
  align-content:stretch!important
}
.align-self-auto {
  align-self:auto!important
}
.align-self-start {
  align-self:flex-start!important
}
.align-self-end {
  align-self:flex-end!important
}
.align-self-center {
  align-self:center!important
}
.align-self-baseline {
  align-self:baseline!important
}
.align-self-stretch {
  align-self:stretch!important
}
.order-first {
  order:-1!important
}

/** **/
.chat .chat-content.chat-left {
  border-start-end-radius:6px
}
.chat .chat-content.chat-right {
  border-start-start-radius:6px;
}

.chat .chat-content {
  border-end-end-radius:6px;
  border-end-start-radius:6px
}
.chat .chat-content.chat-left {
  border-start-end-radius:6px
}
.chat .chat-content.chat-right {
  border-start-start-radius:6px;
}

audio {
  max-width: 100%;
  opacity: 0.6;
}

audio:hover {
  opacity: 1;
}

.chat-right .ablock {
  text-align: right;
  /* padding-top: 7px; */
  font-size:0;
}

</style>

<style lang="scss" scoped>

.trans-btn {
  padding: 5px;
}
.bold {
  font-weight: bold;
}
.trans-col-left {
  padding-right: 5px;
}
.trans-col-right {
  padding-left: 5px;
}
.trans-col-left .badge, .trans-col-right .badge {
  width: 100%;
}

.chats .chat-body .chat-content {
    word-break: break-word;
  }
  // $btn-color: #eceff1;
  // $btn-size : 32px;
  // $duration : 300ms;
  // $easing   : cubic-bezier(0, 0, .2, 1);
  // $half-size: ($btn-size / 2);

  // *,
  // *:before,
  // *:after {
  //   box-sizing: border-box;
  // }

  // .play-btn {
  //   // position    : absolute;
  //   // left        : 50%;
  //   // top         : 50%;
  //   // margin-left : -$half-size;
  //   // margin-top  : -$half-size;
  //   display: inline-block;
  //   width       : $btn-size;
  //   height      : $btn-size;
  //   border-style: solid;
  //   border-color: transparent $btn-color transparent $btn-color;
  //   border-width: $half-size 0 $half-size $btn-size;

  //   &.stop,
  //   &.to-play {
  //     transition  : transform           $duration $easing,
  //                   border-top-width    $duration $easing,
  //                   border-bottom-width $duration $easing;
  //   }
  //   &.stop {
  //     transform   : rotate(90deg);
  //     border-width: 0 0 0 $btn-size;
  //   }
  //   &.to-play {
  //     transform   : rotate(180deg);
  //     border-width: $half-size $btn-size $half-size 0;
  //   }
  // }
</style>
