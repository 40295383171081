import { render, staticRenderFns } from "./ChatLog.vue?vue&type=template&id=485be966&scoped=true&"
import script from "./ChatLog.vue?vue&type=script&lang=js&"
export * from "./ChatLog.vue?vue&type=script&lang=js&"
import style0 from "./ChatLog.vue?vue&type=style&index=0&id=485be966&prod&lang=scss&scoped=true&"
import style1 from "./ChatLog.vue?vue&type=style&index=1&id=485be966&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485be966",
  null
  
)

export default component.exports