import Vue from 'vue'

// const userData = JSON.parse(localStorage.getItem('userData'))
let socket
// const socket = new WebSocket('ws://api.topolingua.com:8080/ws/chat/0108f9f5-3da6-4154-bb15-edaa27fa31ba/')

const emitter = new Vue({
  methods: {
    connect() {
      const userData = JSON.parse(localStorage.getItem('userData')) // new 13-03-2024
      if (!userData) { // new 13-03-2024
        return
      }
      // socket = new WebSocket(`ws://api.topolingua.com:8080/ws/chat/${userData.id}/?token=${localStorage.getItem('accessToken')}`)
      socket = new WebSocket(`wss://api.topolingua.com/ws/chat/${userData.id}/?token=${localStorage.getItem('accessToken')}`)

      socket.onmessage = msg => {
        emitter.$emit('message', msg.data)
      }
      socket.onerror = err => {
        emitter.$emit('error', err)
        console.error('Socket encountered error: ', err.message, 'Closing socket')
        socket.close()
        socket = null // prevent memory leak
      }

      // eslint-disable-next-line no-unused-vars
      socket.onclose = e => {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.')
        setTimeout(() => {
          // console.log('***********************------------------------ setTimeout this.connect() ')
          this.connect()
        }, 1000)

        // if (socket) { // new 13-03-2024
        //   socket.close() // new 13-03-2024
        //   socket = null //  // new 13-03-2024
        //   console.log('Socket is closed. Reconnect will be attempted in 1 second.')
        //   setTimeout(() => {
        //     this.connect()
        //     console.log('***********************------------------------ setTimeout this.connect() ')
        //   }, 1000)
        // }
      }
    },
    send(message) {
      if (socket.readyState === 1) socket.send(message)
    },
    // close() { // new 13-03-2024
    //   if (socket) {
    //     socket.close()
    //     socket = null
    //   }
    // },
  },
})

emitter.connect()

export default emitter
